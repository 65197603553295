import { graphql } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import FormFields from "../../static/forms/event.json"
import ContactDetails from "../components/ContactDetails/ContactDetails"
import EventAgenda from "../components/EventAgenda/EventAgenda"
import EventBanner from "../components/EventBanner/EventBanner"
import EventQuote from "../components/EventQuote/EventQuote"
import EventSpeakers from "../components/EventSpeakers/EventSpeakers"
import EventWithIcon from "../components/EventWithIcon/EventWithIcon"
import IconEvent from "../components/IconEvent/IconEvent"
import SecondaryHeader from "../components/SecondaryHeader/SecondaryHeader"
import SectionViewerComponent from "../components/SectionViewerComponent/SectionViewerComponent"
import TwoColBlock from "../components/TwoColBlock/TwoColBlock"
import DefaultForm from "../components/forms/default-form-layout"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useDeviceMedia from "../hooks/useDeviceMedia"

const EventTemplate = ({ data }, props) => {
  const PageData = data?.strapiEvent
  const [show, setShow] = useState(false)
  const [idArray, setIdArray] = useState([])

  const whatsappData =
    data?.allStrapiSiteConfig?.edges?.length > 0 &&
    data?.allStrapiSiteConfig?.edges[0]

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      if (sessionStorage.getItem("eventform") !== "true") {
        setShow(true)
      }
      sessionStorage.setItem("eventform", "true")
    }, 20000)
    return () => clearTimeout(timer)
  }, [])

  const formData = PageData?.modules?.filter(
    data => data.__typename === "STRAPI__COMPONENT_COMPONENTS_FORM_CONTENT"
  )
  const form_data = formData?.length > 0 ? formData : ""

  const formContent = form_data?.length > 0 ? form_data[0] : ""

  const userContent = parse(formContent?.user_content?.data?.user_content)

  const { isLargeScreen } = useDeviceMedia()

  useEffect(() => {
    setTimeout(() => {
      const nodeList =
        typeof document !== "undefined"
          ? document.querySelectorAll("section")
          : ""
      const array = [...nodeList]
      const filteredArray = array.filter(element => element.hasAttribute("id"))
      const result = filteredArray.map(element => element.getAttribute("id"))
      setIdArray(result)
    }, 1000)
  }, [])

  const getContactModule=PageData?.modules?.find(item=>item.__typename ==="STRAPI__COMPONENT_PAGE_MODULES_CONTACT_MODULE")
  return (
    <Layout isEvent={true}>
      <SecondaryHeader
        idArray={idArray}
        isEvent
        whatsapp_num={PageData?.whatsapp_no}
      />

      {/* <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title}>
      </SEO> */}
      <EventBanner
        handleModal={handleModal}
        title={PageData?.title}
        {...PageData.banner}
        id={PageData?.strapi_id}
        imagetransforms={
          PageData.imagetransforms?.banner_section_banner_image_Transforms
        }
      />
      {isLargeScreen && (
        <SectionViewerComponent
          idArray={idArray}
          isEvent
          whatsapp_num={PageData?.whatsapp_no}
        />
      )}
      {PageData?.modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_ICON_CONTENT" &&
              (item?.layout === "event_1" ? (
                <IconEvent {...item} />
              ) : item?.layout === "event_2" ? (
                <EventWithIcon
                  id={PageData.strapi_id}
                  imagetransforms={
                    PageData.imagetransforms?.icon_section_image_Transforms
                  }
                  {...item}
                />
              ) : item?.layout === "event_speakers" ? (
                <EventSpeakers
                  id={PageData.strapi_id}
                  imagetransforms={
                    PageData.imagetransforms?.icon_section_image_Transforms
                  }
                  {...item}
                />
              ) : null)}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT" && (
              <TwoColBlock
                id={PageData.strapi_id}
                imagetransforms={PageData.imagetransforms}
                {...item}
              />
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_QUOTE_SLIDER" && (
              <EventQuote {...item} />
            )}

            {item?.__typename ===
              "STRAPI__COMPONENT_COMPONENTS_LOCATION_COMPONENT" && (
              <TwoColBlock
                id={PageData.strapi_id}
                imagetransforms={PageData.imagetransforms}
                left_right_block={
                  item.location_module ? [item.location_module] : ""
                }
                locationblock={true}
              />
            )}

            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_EVENT_AGENDA" && (
              <EventAgenda {...item} />
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CONTACT_MODULE" && (
              <>
                <ContactDetails
                  data={{
                    contact_info: item,
                    address: PageData.address,
                    form_data: form_data,
                    title: PageData.title,
                  }}
                  whatsappData={whatsappData}
                />
              </>
            )}
          </div>
        )
      })}
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form"
      >
        <Modal.Header>
          <div className="modal-header-container">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-form-wrapper">
            <div>
              <DefaultForm
                fields={FormFields}
                formTitle={getContactModule?.title?getContactModule?.title:""}
                userContent={userContent}
                to_email_id={formContent?.admin_mail}
                emailSubUser={formContent?.email_subject_user}
                emailSubAdmin={formContent?.email_subject_admin}
                selectType="events"
                thankingAddress={PageData?.address}
                pageTitle={PageData?.title}
                form_id_value="event-banner-id"
                phone_id_value="desktop-id-event"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const eventData = data?.strapiEvent
  let myimgtransforms = ""
  if (eventData?.imagetransforms?.banner_section_banner_image_Transforms) {
    myimgtransforms = JSON.parse(
      eventData?.imagetransforms?.banner_section_banner_image_Transforms
    )
  }

  let imageUrl=""

  const findImage = eventData?.banner?.image?.url?.substring(eventData?.banner?.image?.url?.indexOf("i.prod"));
  const ProcessedResult=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
  imageUrl=ProcessedResult?ProcessedResult["744x838"]:eventData?.banner?.image?.url
  

  const metaTitle = `${eventData?.title}`
  const metaDesc = `Know more about ${eventData?.title} here.`

  return <SEO title={metaTitle} description={metaDesc} imageUrl={imageUrl}></SEO>
}

export default EventTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiEvent(id: { eq: $page_id }) {
      ...EventFragment
      modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_ICON_CONTENT {
          __typename
          ...IconFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT {
          __typename
          ...ImageContentFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_QUOTE_SLIDER {
          __typename
          ...QuoteFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_EVENT_AGENDA {
          __typename
          ...AgendaFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_CONTACT_MODULE {
          __typename
          ...EvantContactFragment
        }
        ... on STRAPI__COMPONENT_COMPONENTS_LOCATION_COMPONENT {
          __typename
          id
          location_module {
            cta_label
            latitude
            longitude
            image {
              url
            }
            layout
            menu {
              slug
            }
            description {
              data {
                description
              }
            }
            title
          }
        }
        ... on STRAPI__COMPONENT_COMPONENTS_FORM_CONTENT {
          __typename
          id
          email_subject_admin
          email_subject_user
          user_content {
            data {
              user_content
            }
          }
          admin_mail
        }
      }
    }
    allStrapiSiteConfig {
      edges {
        node {
          whatsapp_message
        }
      }
    }
  }
`
