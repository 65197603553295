import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './assets/styles/_index.scss';
import ScrollAnimation from "react-animate-on-scroll";
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")


const IconEvent = (props) => {
    // console.log(props)

    return (
        <section className={`icon-event section_item`} id="overview">
            <Container>
                <Row>
                    <Col lg={12}>
                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
                        <div className="event-top-block">
                            {props.title && <h2>{props.title}</h2>}

                            {props.description && <ContentModule Content={props.description?.data?.description} />}
                        </div>
                        </ScrollAnimation>
                        {props?.icon_module?.length > 0 ?
                            <div className="icons  justify-content-between align-items-center">
                                {props?.icon_module?.map((item, i) => {
                                    return (
                                        <ScrollAnimation className="icon-item-bk" animateIn="animate__slideInUp" animateOnce delay={i*100} offset={100}>
                                         {/* <div className="icon-item-bk"> */}
                                            <div className="title">{item?.title}</div>
                                            {item.description && <ContentModule Content={item.description?.data?.description} />}
                                        </ScrollAnimation>
                                    )
                                })}
                            </div>
                            : ""}


                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default IconEvent